.title {
  width: 100vw;
  font-size: 8vh;
  position: absolute;
  color: white;
  text-align: center;
  margin-top: 0;
}

.submit {
  font-size: 3vh;
  position: absolute;
  padding: 12px;
  color: black;
  right: 0;
  bottom: 0;
}
